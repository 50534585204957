<template>
    <div class="red-back">
        <vue-header :fixed="true" title="健康红包" />
        <div v-if="info && red_info">
          <div :class="{without:redType==2}">
            <div class="red-packet" :class="{noRed:redType==3}">
                <!-- <img src="../../../../images/redPacket.png"/> -->
                <div class="red-user flex flex-x flex-y">
                  <div class="flex"  >
                      <div class="red-user-img">
                        <img :src="info.avatarUrl" v-if="info.avatarUrl"/>
                        <img src="../../../../images/user.png" v-else/>
                      </div>
                      <div class="red-user-text flex-1">
                        <div class="red-user-title">您的健康顾问{{info.name||info.nikeName}}</div>
                        <div>送您一张价值{{red_info.price}}元的中医健康评估大红包</div>
                      </div>
                  </div>
                </div>
                <div class="red-position-box">
                  <div class="red-position" @click="receiveFun" v-if="!(redType==3||redType==2)">
                      领取前往检测
                      <div class="packet">价值¥{{red_info.price}}的健康红包</div>
                  </div>
                </div>
                <div class="blessing-words">{{red_info.blessing}}</div>
            </div>
          </div>
        </div>
        <div class="red-top">
          <div class="red-introduce">
            <div class="introduce-title flex">
                <div class="flex-1 flex flex-y">
                  <div class="flex-1"></div>
                  <img src="../../../../images/redEnvelope/icon-left.png"/>
                </div>
                <div>了解健康红包</div>
                <div class="flex-1 flex flex-y">
                  <img src="../../../../images/redEnvelope/icon-right.png"/>
                  <div class="flex-1"></div>
                </div>
            </div>
            <p>健康红包内含有一张健康评估券，可用于AI中医智能健康评估，获取您的专属健康报告，随时了解自己的健康状态和定制养生方案。</p>
            <p>未领取的健康红包，也可以转发给好友，由他领取和使用。</p>
          </div>
          <div class="red-introduce">
            <div class="introduce-title flex">
                <div class="flex-1 flex flex-y">
                  <div class="flex-1"></div>
                  <img src="../../../../images/redEnvelope/icon-left.png"/>
                </div>
                <div>权威&专业背书</div>
                <div class="flex-1 flex flex-y">
                  <img src="../../../../images/redEnvelope/icon-right.png"/>
                  <div class="flex-1"></div>
                </div>
            </div>
            <div class="red-gang">
                <img src="../../../../images/redEnvelope/guang.png"/>
            </div>
          </div>
          <div class="red-introduce">
            <div class="introduce-title flex">
                <div class="flex-1 flex flex-y">
                  <div class="flex-1"></div>
                  <img src="../../../../images/redEnvelope/icon-left.png"/>
                </div>
                <div class="introduce-tool">智能中医健康评估：面诊+舌诊+问诊</div>
                <div class="flex-1 flex flex-y">
                  <img src="../../../../images/redEnvelope/icon-right.png"/>
                  <div class="flex-1"></div>
                </div>
            </div>
            <div class="red-text-nk">泉脉智能中医大脑</div>
            <div class="red-gang">
                <img src="../../../../images/redEnvelope/sang.png"/>
            </div>
          </div>
          <div class="red-introduce">
            <div class="introduce-title flex">
                <div class="flex-1 flex flex-y">
                  <div class="flex-1"></div>
                  <img src="../../../../images/redEnvelope/icon-left.png"/>
                </div>
                <div>您的健康顾问</div>
                <div class="flex-1 flex flex-y">
                  <img src="../../../../images/redEnvelope/icon-right.png"/>
                  <div class="flex-1"></div>
                </div>
            </div>
            <div class="flex flex-x" v-if="info">
                <div class="heah-img">
                    <div class="heah-png">
                        <div>{{info.name||info.nikeName}}</div>
                        <div>{{info.phone}}</div>
                        <div>{{info.company_name}}</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="packet-bottom flex" v-if="!(redType==3||redType==2)">
            <div class="packet-share flex-1" @click="shareFun">分享给好友使用</div>
            <div class="packet-draw" @click="receiveFun">立即领取</div>
        </div>
        <div class="alert" v-if="shareBool" @click="closeFun">
            <div class="alert-top">
                <img src="../../../../images/share.png" class="alert-share" />
            </div>
        </div>
    </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions , mapState } from 'vuex';
export default {
  name: 'redPacket',
  components:{
    vueHeader
  },
  data(){ 
        return {
            shareBool:false,
            redType:1         //1正常 2红包已抢完  3红包过期 
        }
    },
  created:function(){ 
    let _this = this;
    this.configData();
    this.axiosRedInfo({
      data:{
        hongbao_code:this.$route.params.id
      },
      _this:this,
      fun:(data)=>{
        if(data.test_count-data.lingqu_count==0 && data.can_test==0){    //红包已抢完
              _this.redType = 2;
        }else if(data.is_expire){   //红包过期
              _this.redType = 3;
        }else if(data.test_count-data.lingqu_count==0 && data.can_test!==0){  //跳转
              _this.$router.push(`/redEnvelope/gift/${_this.$route.params.id}`)
        }else if(!data.is_expire && data.lingqu_count!==0){    //正常
              _this.redType = 1;
        }
      }
    });
  },
  computed:{
      ...mapState('profile',['info']),
      ...mapState('redEnvelope',['red_info'])
  },
  methods:{
      closeFun(){
          this.shareBool=false;
      },
      shareFun(){
          this.shareBool = true;
          let wx  = window.wx;
          let _this = this;
          wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
              wx.updateAppMessageShareData({ 
                  title: `${_this.info.name||_this.info.nikeName}送您健康大红包啦`, // 分享标题
                  desc: '送您一张AI中医智能评估卷,随时掌握健康状态！', // 分享描述
                  link: `${document.location.protocol}//${window.location.host}/redEnvelope/packet/${_this.$route.params.id}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                  success: function () {} 
              })
              wx.updateTimelineShareData({ 
                  title: `${_this.info.name||_this.info.nikeName}送您健康大红包啦`, // 分享标题
                  link: `${document.location.protocol}//${window.location.host}/redEnvelope/packet/${_this.$route.params.id}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                  success: function () {}
              })
          });
      },
      receiveFun(){   //领取红包
          this.axiosLingqu({
            data:{
              hongbao_code:this.$route.params.id
            },
            fun:(bool)=>{
                let query = '';
                if(bool){
                    query = `?no=true`
                }
                this.$router.push({
                    path:`/redEnvelope/gift/${this.$route.params.id}${query}`,
                });
            }
          })
      },
      ...mapActions('profile',['axiosMain']),
      ...mapActions('user',['configData']),
      ...mapActions('redEnvelope',['axiosRedInfo','axiosLingqu'])
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'redPacket';
</style>